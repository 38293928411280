/*@font-face {
  font-family: SegoeUI;
  src:
      local("Segoe UI Light"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2) format("woff2"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff) format("woff"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf) format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: SegoeUI;
  src:
      local("Segoe UI Semilight"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2) format("woff2"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff) format("woff"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf) format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: SegoeUI;
  src:
      local("Segoe UI"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2) format("woff2"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff) format("woff"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: SegoeUI;
  src:
      local("Segoe UI Bold"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2) format("woff2"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff) format("woff"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf) format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: SegoeUI;
  src:
      local("Segoe UI Semibold"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2) format("woff2"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff) format("woff"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf) format("truetype");
  font-weight: 700;
}*/

@font-face {
  font-family: Speedee;
  src: local("Speedee Light"),
    url("../fonts/woff2/Speedee_W_Lt.woff2") format("woff2"),
    url("../fonts/woff/Speedee_W_Lt.woff") format("woff"),
    url("../fonts/truetype//Speedee_A_Lt.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: Speedee;
  src: local("Speedee Semilight"),
    url("../fonts/woff2/Speedee_W_Lt.woff2") format("woff2"),
    url("../fonts/woff/Speedee_W_Lt.woff") format("woff"),
    url("../fonts/truetype//Speedee_A_Lt.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: Speedee;
  src: local("Speedee UI"),
    url("../fonts/woff2/Speedee_W_Lt.woff2") format("woff2"),
    url("../fonts/woff/Speedee_W_Lt.woff") format("woff"),
    url("../fonts/truetype//Speedee_A_Lt.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Speedee;
  src: local("Speedee UI Bold"),
    url("../fonts/woff2/Speedee_W_Lt.woff2") format("woff2"),
    url("../fonts/woff/Speedee_W_Lt.woff") format("woff"),
    url("../fonts/truetype//Speedee_A_Lt.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: Speedee;
  src: local("Speedee Bold"),
    url("../fonts/woff2/Speedee_W_Bd.woff2") format("woff2"),
    url("../fonts/woff/Speedee_W_Bd.woff") format("woff"),
    url("../fonts/truetype//Speedee_A_Bd.ttf") format("truetype");
  font-weight: 700;
}
