.margin-0{
  margin: 0;
}
.margin-right-0{
  margin-right:0 !important;
}
.padding-left-0{
  padding-left:0 !important;
}
.padding-right-0{
  padding-right:0 !important;
}
.padding-left-md-0{
  @media (min-width: 1025px) {
  padding-left:0 !important;
  }
}
