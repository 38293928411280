@import "./_variables.scss";
@import "./_variable-colors.scss";
body {
  background: rgb(223, 223, 223);
  background: linear-gradient(
    0deg,
    rgba(223, 223, 223, 1) 0%,
    rgba(233, 233, 233, 1) 46%,
    rgba(240, 240, 240, 1) 62%,
    rgba(251, 251, 251, 1) 82%,
    rgba(255, 255, 255, 1) 100%
  );
  color: $body-text-color !important;
  font-family: $default-font-family !important;
  min-height: 98vh;
  margin: 0;
  padding: 0;

  header {
    background-image: url("/assets/images/mcd-right-melt.svg");
    background-position: right -8px;
    background-repeat: no-repeat;
    margin-right: -15px;
    min-height: 70px;
    padding-top: 20px;

    h3 {
      color: #8cb4b6;
      font-family: Speedee;
      font-size: 1.5rem;
      margin-top: 50px;
    }

    .mcd-logo-wrapper {
      background-color: #fff;
      background-image: url("/assets/images/grs-banner2.png");
      background-position: left 12px;
      background-repeat: no-repeat;
      background-size: inherit;
      height: 90px;
      width: 100%;
    }

    .mcd-center-bg {
      background-image: url("/assets/images/center-bg.svg");
      background-position: left -20px;
      background-repeat: repeat-x;

      @media (max-width: 1300px) {
        background-repeat: no-repeat;
      }

      @media (max-width: 991px) {
        background-image: url("/assets/images/GRS-mobile-logo.png");
        background-repeat: no-repeat;
        background-position: 15px 4px;
        background-size: 165px;
        flex: 0 0 66.666667%;
        height: 75px;
        max-width: 66.666667%;

        @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
          only screen and (min--moz-device-pixel-ratio: 1.3),
          only screen and (-o-min-device-pixel-ratio: 2.6/2),
          only screen and (min-device-pixel-ratio: 1.3),
          only screen and (min-resolution: 124.8dpi),
          only screen and (min-resolution: 1.3dppx) {
          background-image: url("/assets/images/GRS-mobile-logo-2x.png")
            no-repeat 15px center;
          background-size: 200px;
        }
      }

      @media (max-width: 576px) {
        background-size: 175px;
        flex: 0 0 60%;
        max-width: 60%;
      }
    }

    .mcd-user-logon {
      @media (max-width: 991px) {
        margin-top: 15px;
        position: absolute;
        right: 0;
        width: 65%;
      }

      @media (max-width: 767px) {
        .logon-user {
          display: none;
        }
      }
    }

    @media (max-width: 991px) {
      background-image: url("/assets/images/mobile-melt.svg");
      background-position: right -5px;
      padding-top: 5px;
    }

    .toggle-menu-button {
      background: transparent;
      display: none;
      position: absolute;
      margin-top: 10px;
      border: none;
      color: #ffbc0d;
      z-index: 98;

      @media (max-width: 1024px) {
        display: block;
      }

      @media (min-width: 992px) and (max-width: 1024px) {
        top: 95px;
        margin-left: -10px;
      }
    }

    .role-menu {
      position: absolute;
    }
  }

  .outer-wrapper {
    background: transparent;
    background-image: url("/assets/images/left-melt.svg");
    background-position: 0 0;
    background-repeat: no-repeat;
    margin: 0;
    padding-bottom: 20px;

    @media (max-width: 991px) {
      background: none;
    }
  }

  .mat-card {
    font-family: $default-font-family;

    .mat-card-header {
      padding-left: 15px !important;
    }
  }

  .main-content-panel {
    overflow-x: hidden;
  }

  .col-left-nav {
    @media (min-width: 1025px) {
      max-width: 320px;
      min-width: 260px;
    }

    @media (max-width: 1024px) {
      position: absolute;
      z-index: 999;
      height: 100%;
      display: none;

      &::before {
        content: "";
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 0;
        will-change: opacity;
        transition: opacity 0.6s cubic-bezier(0, 0, 0.3, 1);
      }
    }

    &.mcd-visible {
      display: block;

      &::before {
        opacity: 1;
      }
    }

    &.mcd-animate {
      transition: transform 0.13s cubic-bezier(0, 0, 0.3, 1);
    }
  }

  @media (max-width: 1199px) {
    .col-left {
      flex: 0 0 25.666667%;
      max-width: 25.666667%;
    }

    .col-center {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }

  @media (max-width: 991px) {
    .col-left {
      display: none;
    }

    .col-center {
      width: 60%;
    }

    .col-right {
      width: 40%;
    }

    .role-menu {
      position: absolute;
    }
  }

  &.freeze {
    height: 100vh;
    overflow-y: hidden;
  }
  // Filter chip styles
  .filter-panel {
    padding: 0 10px;

    .mcd-filter-tag-wrapper {
      @media only screen and (max-width: 767px) {
        margin-top: 15px;
      }
    }

    @media only screen and (max-width: 767px) {
      padding: 0 0;
    }
  }

  .filter-panel-row {
    padding-bottom: 10px;

    .filter-panel {
      & + .collpsible-panel {
        .btn-collapse {
          display: none;
        }

        .btn-expand {
          display: none;
        }
      }
    }

    .collpsible-panel {
      width: 100%;
      text-align: right;
    }

    &.freeze {
      position: fixed;
      background: #fff;
      z-index: 99;
      margin-right: 31px;
      -webkit-box-shadow: 0 8px 6px -6px rgba(189, 189, 189, 0.75);
      -moz-box-shadow: 0 8px 6px -6px rgba(189, 189, 189, 0.75);
      box-shadow: 0 8px 6px -6px rgba(189, 189, 189, 0.75);
      top: 0;

      .filter-panel {
        &.collapsed {
          height: 0;
          overflow: hidden;

          & + .collpsible-panel {
            .btn-collapse {
              display: none;
            }

            .btn-expand {
              display: block;
              float: right;
              margin-right: 10px;
              line-height: 52px;
            }
          }
        }

        &.expanded {
          height: auto;
          overflow: visible;

          & + .collpsible-panel {
            .btn-expand {
              display: none;
            }

            .btn-collapse {
              display: block;
              float: right;
              margin-right: 10px;
              line-height: 52px;
            }
          }
        }
      }

      @media (max-width: 1024px) {
        position: relative;
      }
    }
    // Tab and mobile view
    @media (max-width: 1024px) {
      background: #fff;
      margin-right: 16px;
      padding-bottom: 0;
      z-index: 998;

      .filter-panel {
        & + .collpsible-panel {
          margin-top: -15px;

          .btn-collapse {
            display: none;
          }

          .btn-expand {
            display: block;
            float: right;
          }
        }

        height: 0;
        overflow: hidden;

        &.expanded {
          height: auto;
          overflow: visible;

          & + .collpsible-panel {
            .btn-collapse {
              display: block;
              float: right;
            }

            .btn-expand {
              display: none;
            }
          }
        }
      }

      & + .table-wrapper {
        margin-top: 20px;
      }
    }
    //Edge Hacks
    &.freeze {
      @supports (-ms-ime-align: auto) {
        @media (max-width: 1920px) {
          width: 80.72%;
        }

        @media (max-width: 1370px) {
          width: 73%;
        }

        @media (max-width: 1299px) {
          width: 69%;
        }
      }
      // End EDGE
      //IE 11 Hacks
      @media all and (-ms-high-contrast: none) {
        @media (max-width: 1920px) {
          width: 80.72%;
        }

        @media (max-width: 1370px) {
          width: 73%;
        }

        @media (max-width: 1299px) {
          width: 69%;
        }
      }

      & + .table-wrapper {
        margin-top: 20px;
      }
    }
  }
}
::ng-deep .mat-calendar-header {
  padding-top: 0 !important;
}
.nrbes-Benefit {
  p {
    word-break: break-all;
  }
}
