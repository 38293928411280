@import "./_variables.scss";
//Stepper header remover
.mat-horizontal-stepper-header-container {
  display: none !important;
}
.mat-horizontal-content-container {
  padding: 0 !important;
}
.modal-footer {
  padding: 5px;
}
.mat-radio-button.mat-accent.mat-radio .mat-radio-persistent-ripple {
  background-color: #009688 !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple {
  background-color: #009688 !important;
}

// Modal Vertically center
.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
button {
  outline: 0 !important;
}
.mat-input-element,
.mat-form-field-label {
  font-family: $default-font-family !important;
}


.equipment-menu {
  max-height: none;
  .mat-option {
    font-family: $default-font-family !important;
  }
}
.mat-button, .mat-fab, .mat-flat-button, .mat-icon-button, .mat-mini-fab, .mat-raised-button, .mat-stroked-button{
  font-family: $default-font-family !important;
}
.mat-stepper-horizontal, .mat-stepper-vertical{
  font-family: $default-font-family !important;
}
.mat-option {
  font-family: $default-font-family !important;
  .mat-option-text{
    font-family: $default-font-family !important;
  }
}
.mcd-grbes-form .mat-form-field{
  margin-bottom: 10px;
}
//Radio Color Overrides
.mat-radio-button.mat-accent {
  &.mat-radio-checked {
    .mat-radio-outer-circle {
      border-color: #009688 !important;
    }
  }
  .mat-radio-inner-circle {
    background-color: #009688 !important;
  }
}
.modal-backdrop.fade.show {
  z-index: 99 !important;
}
.modal {
  z-index: 99 !important;
}
.mcd-page-loader{
  .loader{
      position: fixed !important;
      left:50% !important;
  }
}
