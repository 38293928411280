@import "helpers/_variables.scss";
@import "helpers/_variable-colors.scss";
@import "helpers/_mat-menu.scss";
@import "helpers/_utilities.scss";

//Fonts
@import "helpers/_fonts.scss";

//Containers
@import "helpers/containers/card.scss";
@import "helpers/containers/statement.scss";
@import "helpers/containers/modal-dialog.scss";

//Theme Overrides
@import "helpers/themes/buttons.scss";

// //Material Theme
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "helpers/_layout.scss";

//Tables
@import "helpers/table_grid.scss";

// pagination
@import "helpers/_pagination.scss";

// Style Overrides
@import "helpers/_overrides.scss";

//Quill styles
@import "helpers/quill.core.css";
@import "helpers/quill.bubble.css";
@import "helpers/quill.snow.css";

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

* {
  font-family: "Speedee";
}
.float-right {
  float: right !important;
}

body {
  overflow-x: hidden;
}

.mat-menu-content:not(:empty) {
  padding: 0 !important;
}
