.pagination{
  .page-item{
    .page-link{
      border:none;
      border-bottom: solid 4px transparent;
      color: #7c7c7c;
      &:hover{
        background-color: transparent;
        border-bottom: solid 4px #db0007;
      }
      @media only screen and (max-width: 767px) {
        padding: .5rem .65rem !important;
      }
    }
    &.active{
      .page-link{
        background-color: transparent;
        border-color: #db0007;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    margin-right: -10px;
  }
}
