@import "../_variable-colors.scss";

.mcd-card-header-dateModified {
  background-color: #f7f9fa;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  padding: 3px 0;
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  .mat-card-header-text{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .mcd-card-title-dateModified {
    color: #1b1b1b;
    // font-weight: bold;
    // font-size: 1.125rem;
    line-height: 2.5rem;
    margin: 0;
  }
}


.mcd-card {
  background-color: $white-color;
  border-radius: 0.2rem !important;
  box-shadow: none !important;
  margin-top: 10px;
  padding: 0 !important;
  padding-bottom: 15px !important;
  width: auto;
  .mcd-card-header {
    background-color: #f7f9fa;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    padding: 3px 0;
    display: flex;
    .mcd-card-title {
      color: #1b1b1b;
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 2.5rem;
      margin: 0;
    }
    .mcd-card-add-btn {
      position: absolute;
      right: 0;
      .mat-button-wrapper {
        .material-icons {
          font-size: 2.5rem;
        }
      }
      @media (max-width: 576px) {
        right: -15px;
      }
    }
    @media (max-width: 991px) {
      background-color: #fff;
      .mat-card-header-text{
        margin: auto;
      }
    }
  }
  .mcd-card-content {
    // border: solid 1px #ecebeb;
    border-radius: 0.35rem;
    margin: 15px;
    // padding: 15px;
    padding-bottom: 1px;
  }
  .mat-card-footer {
    padding-bottom: 0 !important;
    @media (max-width: 576px) {
      button {
        width: 100%;
        & + button {
          margin-left: 0px !important;
          margin-top: 10px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    margin-top: 0;
  }
}

.mcd-header-image {
  background-image: url("https://material.angular.io/assets/img/examples/shiba1.jpg");
  background-size: cover;
}
.mat-card-actions {
  margin-left: -15px;
  margin-right: -15px;
}

.mat-card-header .mat-card-title {
  margin-bottom: 0px !important; 
}