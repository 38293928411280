@import "../_variable-colors.scss";
@import "../_variables.scss";

.mcd-statement {
  font-family: Speedee;
  background-color: $white-color;
  border-radius: 0.2rem !important;
  box-shadow: none !important;
  margin-top: 10px;
  padding: 0 !important;
  padding-bottom: 15px !important;
  width: auto;
  text-align: center;

  .mcd-statement-header {
    background-color: #f7f9fa;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    padding: 15px 0;
    align-content: center;
    text-align: center;
    font-weight: 800;
    font-size: 1.500rem;
    color: #1b1b1b;
    //line-height: 2.5rem;
    @media (max-width: 991px) {
      background-color: #fff;

      .mat-statement-header-text {
        margin: auto;
      }
    }
  }

  .mcd-statement-subheader {
    background-color: #f7f9fa;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 1.250rem;
    font-style: oblique;
    line-height: 1.750rem;
    margin-bottom: 8px;
  }

  .mcd-statement-title {
    /*color: #1b1b1b;
    font-weight: 700;*/
    font-family: Speedee;
    //font-size: 1.075rem;
    padding-left: 5px;
    line-height: 1.750rem;
    /*text-align: left*/;
  }

  .mcd-statement-content {
    // border: solid 1px #ecebeb;
    border-radius: 0.35rem;
    margin: 15px;
    // padding: 15px;
    padding-bottom: 1px;
    //font-size: 0.125rem;
  }



  @media (max-width: 991px) {
    margin-top: 0;
  }
}


