.mat-menu-panel {
  .mat-menu-content {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    .mat-menu-item {
      height: 36px;
      line-height: 36px;
    }
  }
}
