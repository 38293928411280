table {
  font-size: 0.875rem;
  margin-bottom: 0;
  th {
    background-color: #f2f2f2;
    border-color: #e5e5e5;
    color: #8f8f8f;
    font-weight: 700;
    height: 40px;
    line-height: 17px;
    padding: 0.25rem 0.75rem !important;
    vertical-align: middle !important;
  }
  tbody {
    tr {
      td {
        color: #8f8f8f;
        font-size: 0.75rem;
        font-weight: 500;
        word-break:break-word;
        .action-controls {
          color: #444;
        }
        .grid-actions {
          .col-6 {
            padding: 0px 5px;
            line-height: 0;
            margin-top: -5px;
            a {
              cursor: pointer;
              display: block;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
