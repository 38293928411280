.modal-dialog{
  min-width: 55vw;
  .modal-content{
    border-radius:0;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border: none;
    border-color: #1f1f1f45;
    .modal-footer{
      border: none;
      padding:15px;
    }
    .modal-header{
      background: #f7f9fa;
      border: none;
      border-top-left-radius: .5rem;
      border-top-right-radius: .5rem;
      padding: 12px 15px 12px 35px;
      .modal-title{
        color: #1b1b1b;
        font-size: 1.125rem;
        font-weight: bold;
      }
      .close {
        padding: 10px 20px;
        span{
          font-size: 2.25rem;
        }
       }
    }
    .modal-body{
      padding-right: 0;
    padding-bottom: 0;
      .mat-form-field{
        margin-bottom: 1rem;
        width:100%;
        textarea{
          resize:none;
        }
      }
      .mcd-edit-form{
        padding: 0 20px;
      }
    }
    .modal-footer{
      @media (max-width: 576px) {
        display: block;
      }
      button{
        @media (max-width: 576px) {
          display: block;
          width:100%;
          & + button{
            margin: 10px 0 0 0;
          }
        }
      }
    }
  }
  @media (max-width: 1199px) {
    min-width: 70vw;
  }
  @media (max-width: 991px) {
    min-width: 80vw;
  }
}
