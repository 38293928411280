@import "../_variable-colors.scss";
.mcd-btn{
  border-radius: 0 !important;
  box-shadow: none !important;
  min-width: 180px !important;
  height: 45px;
  height: 45px;
  &.mcd-btn-primary{
    background-color: #db0007;
    color: $white-color;
  }
  &.mcd-btn-secondary{
    background-color: #ffbc26;
    color: $white-color;
  }
  &.mcd-btn.mcd-btn-secondary[disabled]{
    background-color: #e0e0e0;
  }
  &.mcd-btn-gray{
    background-color: #b79a80;
    color: $white-color;
  }
}
